/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content .comeos-theme-collection {
  font-family: 'Campton';
  padding-bottom: 30px;
}
.altai-theme-content .comeos-theme-collection .comeos-theme {
  position: relative;
  width: 100%;
  height: 200px;
  padding-bottom: 100%;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 17px;
  padding-left: 0;
  cursor: pointer;
  background-size: auto 100%;
  background-position: center;
}
.altai-theme-content .comeos-theme-collection .comeos-theme:hover {
  box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.2);
}
.altai-theme-content .comeos-theme-collection .comeos-theme .comeos-theme-image {
  overflow: hidden;
  position: relative;
}
.altai-theme-content .comeos-theme-collection .comeos-theme .comeos-theme-image .overlay {
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  opacity: .2;
}
.altai-theme-content .comeos-theme-collection .comeos-theme .comeos-theme-image img {
  height: 200px;
}
.altai-theme-content .comeos-theme-collection .comeos-theme .comeos-theme-title {
  color: white;
  font-size: 1.1em;
  -ms-word-break: inherit;
  word-break: inherit;
  font-weight: 600;
  margin-top: 20%;
  margin-left: 26px;
  width: 60%;
  letter-spacing: 1px;
  position: absolute;
}
@media screen and (min-width: 1200px) {
  .altai-theme-content .comeos-theme-collection .comeos-theme .comeos-theme {
    font-size: 1.7em;
  }
}
@media screen and (min-width: 1200px) {
  .altai-theme-content .comeos-theme-collection .comeos-theme {
    height: 260px;
  }
}
